/*----------------------------------------
Navigation
----------------------------------------*/

.navbar-header {
  z-index: 999;
}
.navbar-brand {
  padding: 0;
  height: auto;
  margin-right: 6rem;
  z-index: 9999;
}
.navbar-brand img {
  width: auto;
  margin-right: 10px;
  float: left;
}
.navbar-brand span {
  color: #3b455a;
  font-weight: 600;
  font-size: 20px;
}
.navbar-header .navbar {
  padding: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  /* -webkit-box-pack: justify; */
  /* justify-content: space-between; */
  /* display: -webkit-box; */
  /* display: flex; */
  /* -webkit-transition: background-color 0.5s linear, padding 0.2s ease-in,
    -webkit-transform 0.4s ease-in-out; */
  /* transition: background-color 0.5s linear, padding 0.2s ease-in,
    -webkit-transform 0.4s ease-in-out; */
  /* transition: transform 0.4s ease-in-out, background-color 0.5s linear,
    padding 0.2s ease-in; */
  /* transition: transform 0.4s ease-in-out, background-color 0.5s linear,
    padding 0.2s ease-in, -webkit-transform 0.4s ease-in-out; */
  box-shadow: 0 2px 4px rgba(81, 107, 152, 0.16);
  width: 100%;
  background: #fff;
}

.navbar-header .navbar.sticky li a,
.navbar-header .navbar.sticky .search-box-btn {
  color: #3b455a;
}

.navbar-header .navbar.sticky {
  background-color: #ffffff;
  box-shadow: 0 8px 28px 2px rgba(61, 61, 61, 0.12);
}

.navbar-header .navbar-nav li a {
  color: #3b455a;
  font-size: 15px;
  padding: 15px 12px !important;
  font-weight: 500;
  line-height: 50px;
}

.navbar-header .navbar-nav li a:hover,
.navbar-header .navbar-nav li a:focus {
  color: #fe8402;
}

.navbar-header .connect-block {
  margin-left: 30px;
  margin-top: 15px;
}

.navbar-header .navbar-nav li a.btn-blue {
  line-height: 25px;
  text-transform: uppercase;
  padding: 6px 35px !important;
  -webkit-transition: box-shadow 0.3s ease-in-out, padding 0.2s ease-in;
  transition: box-shadow 0.3s ease-in-out, padding 0.2s ease-in;
  border-radius: 6px;
  background: #516cf0;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  width: 200px;
}
.navbar-header .navbar-nav li a.btn-blue:hover,
.navbar-header .navbar-nav li a.btn-blue:active:hover,
.navbar-header .navbar-nav li a.btn-blue:focus,
.navbar-header .navbar-nav li a.btn-blue:active:focus,
.navbar-header .navbar-nav li a.btn-blue:active,
.navbar-header .navbar-nav li a.btn-blue.active {
  background-color: #fe8402;
  border-color: #fe8402;
}
@media (min-width: 720px) {
  .navbar-brand img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .icon_menu {
    height: auto;
    color: #5966d6;
  }
}
.navbar-toggler-icon {
  vertical-align: top;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
